import Dropzone from "./dropzone-view";
import {connect} from "react-redux";
import {getPendingFileInternalIds, getUploadedFileInternalIds} from "../../features/poll";
import {createUploadsFromFiles} from "../../features/poll/file-events";

const mapStateToProps = state => {
  return {
    pendingFileInternalIds: getPendingFileInternalIds(state),
    uploadedFileInternalIds: getUploadedFileInternalIds(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadFiles: files => createUploadsFromFiles({files}, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dropzone);
