import React, {useEffect, useState} from "react"
import {Box, Button, List, Typography} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import axios from "axios";
import {FileView} from "./components";

export default function PollConfirmation({selectedPoll, reset}) {
  const [pollData, setPollData] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`/api/polls/${selectedPoll}/`);
      setPollData(result.data);
    };
    fetchData();
  }, [selectedPoll]);

  return (
    <>
      <Alert severity="success">
        <AlertTitle>Übermittlung erfolgreich.</AlertTitle>
        <Typography>Herzlichen Dank für Ihre Angaben!</Typography>
      </Alert>
      <Box my={2}>
        <Typography>Wir haben Ihre Angaben unter der ID <strong>{selectedPoll}</strong> erfasst.</Typography>
      </Box>
      {pollData ? (
        <>
          {pollData.files?.length > 0 ? (
            <Box my={2}>
              <Typography variant="h6">Von Ihnen bereitgestellte Dateien:</Typography>
              <List>
                {pollData.files.map(fileData => (
                  <FileView
                    key={fileData.uuid}
                    {...fileData}
                  />
                ))}
              </List>
            </Box>
          ) : null}
        </>
      ) : null}
      {reset ? (
        <Button
          to="/"
          color="secondary"
          variant="outlined"
          onClick={reset}
        >
          Neues Formular ausfüllen
        </Button>
      ) : null}
    </>
  );
}
