import * as yup from "yup";
import yupLocaleDe from '../../packages/yup-locale-de';

yup.setLocale(yupLocaleDe);

export const POLL_SCHEMA = yup.object().shape({
  voraussetzungen_has_external_id: yup.string().max(255),
  voraussetzungen_external_id_is_mutable: yup.mixed()
    .when('voraussetzungen_has_external_id', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),

  voraussetzungen_has_internal_id: yup.string().max(255),
  voraussetzungen_internal_id_is_mutable: yup.mixed()
    .when('voraussetzungen_has_internal_id', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),

  voraussetzungen_notes: yup.string().max(1024 * 1024),

  sendesystem_has_spots: yup.string().max(255),
  sendesystem_has_external_id: yup.mixed()
    .when('sendesystem_has_spots', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),
  sendesystem_has_internal_id: yup.mixed()
    .when('sendesystem_has_spots', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),
  sendesystem_has_spot_title: yup.mixed()
    .when('sendesystem_has_spots', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),

  sendesystem_notes: yup.string().max(1024 * 1024),

  dispositionssystem_has_spots: yup.string().max(255),
  dispositionssystem_dateiformat: yup.string().max(255),
  dispositionssystem_has_external_id: yup.mixed()
    .when('dispositionssystem_has_spots', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),
  dispositionssystem_has_internal_id: yup.mixed()
    .when('dispositionssystem_has_spots', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),
  dispositionssystem_has_spot_title: yup.mixed()
    .when('dispositionssystem_has_spots', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),
  dispositionssystem_granularity: yup.string().max(255),
  dispositionssystem_software: yup.string().max(255),
  dispositionssystem_andere_moeglichkeit: yup.string().max(1024 * 1024),

  dispositionssystem_notes: yup.string().max(1024 * 1024),

  selbstgebaute_werbespots_libraries: yup.string().max(1024 * 1024),
  selbstgebaute_werbespots_was: yup.string().max(1024 * 1024),
  selbstgebaute_werbespots_format: yup.string().max(1024 * 1024),
  selbstgebaute_werbespots_weg: yup.string().max(1024 * 1024),

  selbstgebaute_werbespots_notes: yup.string().max(1024 * 1024),

  metadaten_has_audiofiles: yup.string().max(255),
  metadaten_where_audiofiles: yup.mixed()
    .when('has_audiofiles', {
      is: value => value && value !== 'fehlt',
      then: yup.bool()
        .transform(value => (value === '' ? undefined : value)),
      otherwise: yup.string().transform(() => undefined),
    }),

  metadaten_notes: yup.string().max(1024 * 1024),

  stations: yup.string().trim().required().max(1024 * 1024),
  ansprechpartner: yup.string().trim().required().max(1024),
  email: yup.string().email().required().max(1024),
  telefon: yup.string().trim().max(1024),
});
