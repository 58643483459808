import Poll from "./poll-view";
import {connect} from "react-redux";
import {getSelectedPoll} from "../../features/poll";

const mapStateToProps = state => {
  return {
    selectedPoll: getSelectedPoll(state),
  };
};

export default connect(
  mapStateToProps,
)(Poll);
