import {Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormGroup, FormHelperText} from "@material-ui/core";
import {Controller} from "react-hook-form";
import React from "react";

export default function Checkbox({errors = {}, control, name, ...props}) {
  return (
    <FormControl error={!!errors[name]} component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={
            <Controller
              control={control}
              defaultValue={false}
              name={name}
              render={({onChange, value, ...props}) => (
                <MuiCheckbox
                  {...props}
                  checked={value === true}
                  onChange={e => onChange(e.target.checked)}
                  color="primary"
                />
              )}
            />
          }
          {...props}
        />
        {errors[name] ? <FormHelperText>{errors[name].message}</FormHelperText> : null}
      </FormGroup>
    </FormControl>
  );
}
