import UploadedFile from "./uploaded-file-view";
import {connect} from "react-redux";
import {deleteFile, getAuthToken, getUploadedFile} from "../../../features/poll";

const mapStateToProps = (state, {internalId}) => {
  const {name, size, uuid, deletionRequested} = getUploadedFile(state)(internalId);

  return {
    name,
    size,
    uuid,
    deletionRequested,
    authToken: getAuthToken(state)(internalId),
  };
};

const mapDispatchToProps = (dispatch, {internalId}) => {
  return {
    deleteFile: auth_token => dispatch(deleteFile({
      internalId,
      auth_token,
      ignoreErrors: true,  // Ensure that potential consistency errors do not prevent form submission.
    })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadedFile);
