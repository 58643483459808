import React from "react";
import {IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from '@material-ui/icons/Error';

export default function PendingFile({name, size, uploadFailed, cancel}) {
  return (
    <ListItem disabled={uploadFailed}>
      <ListItemIcon>
        {uploadFailed ? (
          <ErrorIcon/>
        ) : (
          <CircularProgress size='2rem'/>
        )}
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={`${size} bytes – ${uploadFailed ? "Upload fehlgeschlagen." : "lädt hoch..."}`}
      />
      {(cancel && uploadFailed) ? (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="Löschen" onClick={cancel}>
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}
