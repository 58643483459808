import PendingFile from "./pending-file-view";
import {connect} from "react-redux";
import {deletedFile, getPendingFile} from "../../../features/poll";

const mapStateToProps = (state, {internalId}) => {
  const {name, size, uploadFailed} = getPendingFile(state)(internalId);

  return {
    name,
    size,
    uploadFailed,
  };
};

const mapDispatchToProps = (dispatch, {internalId}) => {
  return {
    cancel: () => dispatch(deletedFile({internalId})),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingFile);
