import React from "react"
import {Box, Typography} from "@material-ui/core";
import {SinglePage} from "../components/scaffold";
import {Poll} from "../components/poll";

export default function App() {
  return (
    <SinglePage>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Umfrage zu Werbung
        </Typography>
        <Poll/>
      </Box>
    </SinglePage>
  );
}
