import MuiTextField from "@material-ui/core/TextField";
import React from "react";

export default function TextField({errors = {}, name, variant = 'outlined', fullWidth = true, ...props}) {
  return (
    <MuiTextField
      {...props}
      name={name}
      variant={variant}
      fullWidth={fullWidth}
      error={!!errors[name]}
      helperText={errors[name]?.message}
    />
  );
}
