import React, {useEffect, useMemo, useState} from "react"
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {Link} from 'gatsby-theme-material-ui';
import {yupResolver} from "@hookform/resolvers/yup";
import {Box, Button, Collapse, Grid, Typography} from "@material-ui/core";
import {POLL_SCHEMA} from "../../features/poll/poll-validation";
import {makeStyles} from "@material-ui/core/styles";
import Dropzone from "./dropzone-redux";
import {Checkbox, Choices, TextField} from "../form";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Assignment from "@material-ui/icons/Assignment";


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    right: 0,
    top: -theme.spacing(6),
  },
  hidden: {
    display: 'none',
  },
}));

function ResetButton({reset}) {
  const classes = useStyles();

  if (!reset) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button
        to="/"
        color="secondary"
        variant="outlined"
        className={classes.button}
        onClick={reset}
      >
        Formular zurücksetzen
      </Button>
    </div>
  );
}

function OptionalFreeTextField({name, bereich, remember}) {
  const {watch, errors, control, register} = useFormContext();

  const value = watch(name);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!show && value) {
      setShow(true);
    }
  }, [show, value]);

  return (
    <Box my={2}>
      {show ? (
        <TextField
          label={<>Freitextfeld für Ihre optionalen Ergänzungen zum Bereich &bdquo;{bereich}&rdquo;</>}
          name={name}
          multiline
          rows={4}
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      ) : (
        <>
          <Button onClick={() => setShow(true)} color="primary" size="small">Freitext hinzufügen</Button>
        </>
      )}
    </Box>
  );
}

function Question({question, children, show=true, ...props}) {
  return (
    <Collapse component={Box} in={show} mt={show ? 2 : 0} {...props}>
      <Alert icon={<Assignment style={{color: '#ccc'}}/>} variant='standard' color='info'>
        <Typography variant="body1">
          {question}
        </Typography>
        <Box mt={2} style={{background: 'white'}}>
          {children}
        </Box>
      </Alert>
    </Collapse>
  );
}

function VoraussetzungenForm({remember}) {
  const {watch, errors, control, register} = useFormContext();

  const classes = useStyles();

  return (
    <Box>
      <Box my={2}>
        <Typography variant="body1" gutterBottom>
          Wir unterstellen, dass bei Ihnen Werbespots angeliefert werden, die einen Namen und eine eindeutige ID haben.
          Die Anlieferung erfolgt über den Vermarkter oder direkt vom Kunden oder von Ihrer eigenen
          Produktionsabteilung.
        </Typography>
      </Box>

      <Question
        question={(
          <>
            Liegt Ihnen für jeden angelieferten Spot eine <strong>vom Zulieferer vergebene ID</strong> vor, die den Spot
            eindeutig identifiziert?
          </>
        )}
      >
        <Choices
          name='voraussetzungen_has_external_id'
          label="Externe ID"
          errors={errors}
          control={control}
          items={{
            vorhanden: "ja – externe ID vorhanden",
            fehlt: "nein – externe ID nicht vorhanden",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('voraussetzungen_has_external_id') === 'vorhanden'}
        question={(
          <>
            Kann es nach Ihrem Kenntnisstand vorkommen, dass das zum Spot gehörige Audiofile vom Zulieferer nachträglich
            verändert / aktualisiert wird, ohne dass die für den Spot vergebene ID angepasst wird?
          </>
        )}
      >
        <Choices
          name='voraussetzungen_external_id_is_mutable'
          label="Eindeutigkeit der externen ID"
          errors={errors}
          control={control}
          items={{
            true: "ja – das Audiofile zu einer externen ID kann sich ändern",
            false: "nein – zu einer externen ID gibt es immer nur ein Audiofile",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <Question
        question={(
          <>
            Wird von Ihrem Haus für jeden Spot eine eigene, <strong>interne ID</strong> vergeben (ggf. ergänzend zur ID
            des Zulieferers, falls vorhanden)?
          </>
        )}
      >
        <Choices
          name='voraussetzungen_has_internal_id'
          label="Interne ID"
          errors={errors}
          control={control}
          items={{
            vorhanden: "ja – interne ID vorhanden",
            fehlt: "nein – interne ID nicht vorhanden",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('voraussetzungen_has_internal_id') === 'vorhanden'}
        question={(
          <>
            Kann es nach Ihrem Kenntnisstand vorkommen, dass das zum Spot gehörige Audiofile vom Zulieferer nachträglich
            verändert / aktualisiert wird, ohne dass die für den Spot vergebene ID angepasst wird?
          </>
        )}
      >
        <Choices
          name='voraussetzungen_internal_id_is_mutable'
          label="Eindeutigkeit der internen ID"
          errors={errors}
          control={control}
          items={{
            true: "ja – das Audiofile zu einer internen ID kann sich ändern",
            false: "nein – zu einer internen ID gibt es immer nur ein Audiofile",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <OptionalFreeTextField
        name="voraussetzungen_notes"
        bereich="Voraussetzungen"
        remember={remember}
      />
    </Box>
  );
}

function SendesystemForm({remember}) {
  const {watch, errors, control, register} = useFormContext();

  const classes = useStyles();

  return (
    <Box>
      <Box my={2}>
        <Typography variant="body1" gutterBottom>
          Ideal wäre es, wenn Informationen zu Musik, Jingles und Werbung gemeinsam im Sendesystem vorliegen und von
          dort exportiert werden können, sodass eine Sammelmeldung erfolgen kann und kein zusätzlicher Aufwand für die
          verschiedenen Ausstrahlungsarten entsteht
        </Typography>
      </Box>

      <Question
        question={(
          <>
            Ist es Ihnen möglich, Angaben zu ausgespielten Werbespots in Ihr Sendesystem bzw. in die Software zu
            überführen, die auch die GEMAGVL4-Sendemeldungen (für Sendemeldung.de) erstellt?
          </>
        )}
      >
        <Choices
          name='sendesystem_has_spots'
          label="Werbespots in Sendesystem"
          errors={errors}
          control={control}
          items={{
            vorhanden: "ja – ausgestrahlte Werbespots liegen in Sendesystem vor",
            fehlt: "nein – ausgestrahlte Werbespots sind in Sendesystem nicht vorhanden",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('sendesystem_has_spots') === 'vorhanden'}
        question={(
          <>
            Welche Informationen zu ausgestrahlten Werbespots könnten in Ihr Sendesystem überführt und von dort exportiert
            werden?
          </>
        )}
      >
        {watch('voraussetzungen_has_external_id') === 'vorhanden' ? (
          <>
            <Checkbox
              name='sendesystem_has_external_id'
              label="extern vergebene Spot-ID"
              errors={errors}
              control={control}
              onBlur={remember}
            /><br/>
          </>
        ) : null}
        {watch('voraussetzungen_has_internal_id') === 'vorhanden' ? (
          <>
            <Checkbox
              name='sendesystem_has_internal_id'
              label="Ihre intern vergebene Spot-ID"
              errors={errors}
              control={control}
              onBlur={remember}
            /><br/>
          </>
        ) : null}
        <Checkbox
          name='sendesystem_has_spot_title'
          label="Titel des Spots"
          errors={errors}
          control={control}
          onBlur={remember}
        />
      </Question>

      <OptionalFreeTextField
        name="sendesystem_notes"
        bereich="Sendesystem"
        remember={remember}
      />
    </Box>
  );
}

function DispositionssystemForm({remember}) {
  const {watch, errors, control, register} = useFormContext();

  const classes = useStyles();

  return (
    <Box>
      <Box my={2}>
        <Typography variant="body1" gutterBottom>
          Sofern die Meldung über das Sendesystem nicht oder nur mit erheblichem Aufwand umsetzbar ist, wäre es
          alternativ denkbar, die benötigten Informationen aus dem Dispositionssystem bereitzustellen. Die Aufbereitung
          der Meldung und ggf. Zusammenführung mit parallelen Musikmeldungen zur Erzeugung einer Gesamtmeldung könnte
          dann durch Sendemeldung.de erfolgen.
        </Typography>
      </Box>

      <Question
        question={(
          <>
            Ist es Ihnen möglich, die genaue Sendung von Werbespots aus Ihrer Disposition zu melden?
          </>
        )}
      >
        <Choices
          name='dispositionssystem_has_spots'
          label="Werbespots in Disposition"
          errors={errors}
          control={control}
          items={{
            vorhanden: "ja – ausgestrahlte Werbespots liegen in Dispositionssystem vor",
            fehlt: "nein – ausgestrahlte Werbespots sind in Dispositionssystem nicht vorhanden",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('dispositionssystem_has_spots') === 'vorhanden'}
        question={(
          <>
            In welches Dateiformat könnten Werbedaten exportiert werden?
          </>
        )}
      >
        <TextField
          label="Dateiformat (z. B. GEMAGVL4, WERB-RF, ...)"
          name="dispositionssystem_dateiformat"
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('dispositionssystem_has_spots') === 'vorhanden'}
        question={(
          <>
            Welche Informationen zu Werbespot-Ausstrahlungen sind vorhanden und können exportiert werden?
          </>
        )}
      >
        {watch('voraussetzungen_has_external_id') === 'vorhanden' ? (
          <>
            <Checkbox
              name='dispositionssystem_has_external_id'
              label="extern vergebene Spot-ID"
              errors={errors}
              control={control}
              onBlur={remember}
            /><br/>
          </>
        ) : null}
        {watch('voraussetzungen_has_internal_id') === 'vorhanden' ? (
          <>
            <Checkbox
              name='dispositionssystem_has_internal_id'
              label="Ihre intern vergebene Spot-ID"
              errors={errors}
              control={control}
              onBlur={remember}
            /><br/>
          </>
        ) : null}
        <Checkbox
          name='dispositionssystem_has_spot_title'
          label="Titel des Spots"
          errors={errors}
          control={control}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('dispositionssystem_has_spots') === 'vorhanden'}
        question={(
          <>
            In welcher Granularität liegen Ausstrahlungszeiten vor? (bitte ankreuzen, was am ehesten zutrifft)
          </>
        )}
      >
        <Choices
          name='dispositionssystem_granularity'
          label="Granularität der Ausstrahlungszeiten"
          errors={errors}
          control={control}
          items={{
            sekundengenau: "genauer Ausstrahlungszeitraum (sekundengenau)",
            werbeblock: "Ausstrahlungen je Werbeblock",
            stunde: "Ausstrahlungen je Stunde",
            tag: "Ausstrahlungen je Tag",
            monat: "Ausstrahlungen je Monat",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <Question
        question={(
          <>
            Welches Dispositions-Softwareprodukt setzen Sie ein?
          </>
        )}
      >
        <TextField
          label="verwendete Dispositionssoftware"
          name="dispositionssystem_software"
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('dispositionssystem_has_spots') === 'fehlt'}
        question={(
          <>
            Welche sonstigen Möglichkeiten haben Sie etwa in der Buchhaltung, wenn es um die Rechnungstellung für
            Werbeschaltungen geht?
          </>
        )}
      >
        <TextField
          label="Möglichkeiten zur Werbemeldung"
          name="dispositionssystem_andere_moeglichkeit"
          multiline
          rows={7}
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Question>

      <OptionalFreeTextField
        name="dispositionssystem_notes"
        bereich="Dispositionssystem"
        remember={remember}
      />
    </Box>
  );
}

function SelbstgebauteWerbespotsForm({remember}) {
  const {watch, errors, control, register} = useFormContext();

  const classes = useStyles();

  return (
    <Box>
      <Box my={2}>
        <Typography variant="body1" gutterBottom>
          Jede Station baut Werbespots für Kunden. Dazu werden Libraries entsprechender Musiktitel verwendet. Uns
          interessiert:
        </Typography>
      </Box>

      <Box my={2}>
        <Typography variant="h6" gutterBottom>
          Welche Libraries nutzen Sie?
        </Typography>
      </Box>

      <Question
        question={(
          <>
            Bitte geben Sie an, welche Libraries Sie nutzen (genaue Firmierung), so dass wir für die weitere Einbindung
            deren Belange in den Workflow Kontakt aufnehmen können:
          </>
        )}
      >
        <TextField
          label="Verwendete Libraries"
          name="selbstgebaute_werbespots_libraries"
          multiline
          rows={4}
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Question>

      <Box my={2}>
        <Typography variant="h6" gutterBottom>
          Welche Meldeverpflichtungen gibt es gegenüber den Libraries?
        </Typography>
      </Box>

      <Question
        question={(
          <>
            Üblicherweise gibt es bei den Libraries zwei unterschiedliche Meldeverpflichtungen: Welche Musik ist überhaupt
            für die Werbung verwendet worden (Herstellungsrecht)? Dann wird oft gefragt, wie oft ein Spot gesendet wurde
            (Senderecht), darum geht es bei der Sendemeldung an die GEMA. Unser Ziel ist es zu erkennen, was die Stationen
            in der Praxis tun müssen, um in diesen Fällen eine doppelte Meldung zu vermeiden.
          </>
        )}
      >
        <Box mt={2}>
          <TextField
            label="Was wird gemeldet?"
            name="selbstgebaute_werbespots_was"
            multiline
            rows={4}
            inputRef={register}
            errors={errors}
            onBlur={remember}
          />
        </Box>
        <Box mt={2}>
          <TextField
            label="Wie / in welchem elektronischen Format wird gemeldet?"
            name="selbstgebaute_werbespots_format"
            multiline
            rows={4}
            inputRef={register}
            errors={errors}
            onBlur={remember}
          />
        </Box>
        <Box mt={2}>
          <TextField
            label="Wie, auf welchem Weg wird gemeldet?"
            name="selbstgebaute_werbespots_weg"
            multiline
            rows={4}
            inputRef={register}
            errors={errors}
            onBlur={remember}
          />
        </Box>
      </Question>

      <OptionalFreeTextField
        name="selbstgebaute_werbespots_notes"
        bereich="Selbstgebaute Werbespots"
        remember={remember}
      />
    </Box>
  );
}


function MetadatenForm({remember}) {
  const {watch, errors, control, register} = useFormContext();

  const classes = useStyles();

  return (
    <Box>
      <Box my={2}>
        <Typography variant="body1" gutterBottom>
          Ziel ist, dass die Meldung von Ausstrahlungszeiträumen und Spot-ID (+ ggf. Titel) regelmäßig ausreicht und
          keine weiteren Metadaten zu im Werbespot enthaltener Musik übermittelt werden müssen. Die Metadaten sollen
          durch die Produzenten bereitgestellt und in einem zentralen Katalog unter der jeweiligen Spot-ID gespeichert
          werden. Die Ergänzung weiterer Metadaten in der XML-Meldung, falls technisch von der GEMA gefordert, könnte
          automatisiert zum Beispiel durch Sendemeldung.de erfolgen.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Um Metadaten für neu produzierte Spots ermitteln zu können, plant die GEMA die Beauftragung eines externen
          Dienstleisters, der Spot-Audiofiles automatisiert auswertet. Zu jeder Spot-ID muss hierzu einmalig ein
          Audiofile angeliefert werden, was typischerweise durch den Produzenten zu erledigen ist.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Bei selbstgebauten Spots und in Ausnahmefällen – etwa, wenn der Spot von einem Produzenten stammt, der die
          Anlieferung des Audiofiles an die GEMA noch nicht umgesetzt hat – kann es erforderlich sein, das zu einer
          Spot-ID zugehörige Audiofile beim Erstellen der Sendemeldung nachzureichen.
        </Typography>
      </Box>

      <Question
        question={(
          <>
            Liegen Ihnen die Audiofiles zu Werbespots zum Zeitpunkt der Erstellung der jeweiligen Sendemeldung noch vor,
            sodass ein fehlendes Werbespot-Audiofile beispielsweise nach Rückfrage durch Sendemeldung.de von Ihnen
            hochgeladen werden könnte?
          </>
        )}
      >
        <Choices
          name='metadaten_has_audiofiles'
          label="Audiofiles bei Erstellung der Sendemeldung ermittelbar?"
          errors={errors}
          control={control}
          items={{
            vorhanden: "ja – Audiofiles liegen zum Zeitpunkt der Sendemeldung vor",
            fehlt: "nein – Audiofiles liegen zum Zeitpunkt der Meldung nicht mehr vor",
            '': "(unbekannt)",
          }}
          onBlur={remember}
        />
      </Question>

      <Question
        show={watch('metadaten_has_audiofiles') === 'fehlt'}
        question={(
          <>
            An welcher Stelle könnte in Ihrem Workflow eine bedarfsweise Anlieferung einzelner Werbespot-Audiofiles
            umgesetzt werden?
          </>
        )}
      >
        <TextField
          label="Stellen im Workflow, an denen Audiofiles zur Verfügung stehen"
          name="metadaten_where_audiofiles"
          multiline
          rows={4}
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Question>

      <OptionalFreeTextField
        name="metadaten_notes"
        bereich="Ermittlung von Metadaten eines Spots"
        remember={remember}
      />
    </Box>
  );
}


function StammdatenForm({remember}) {
  const {register, errors} = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1">
          Bitte teilen Sie uns abschließend noch mit, für welche Stationen Ihre Angaben gelten und wie wir Sie bei
          Rückfragen erreichen können. Vielen Dank!
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          required
          label="Unternehmen / Stationen (Mehrfachnennungen möglich)"
          name="stations"
          multiline
          rows={3}
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          required
          label="Ansprechpartner"
          name="ansprechpartner"
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          required
          label="E-Mail"
          name="email"
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Telefon"
          name="telefon"
          inputRef={register}
          errors={errors}
          onBlur={remember}
        />
      </Grid>
    </Grid>
  );
}

function SendemeldungenForm({remember}) {
  const {errors, control} = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Choices
          name='status'
          label="Status Sendemeldungen"
          errors={errors}
          control={control}
          items={{
            dispensiert: "dispensiert – Wir sind von der Verpflichtung zur Abgabe von Sendemeldungen dispensiert.",
            aktiv: "aktiv – Wir sind derzeit zur Abgabe von Sendemeldungen verpflichtet.",
          }}
          onBlur={remember}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Können Sie uns eine oder mehrere Sendemeldungen aus der Vergangenheit bereitstellen?
        </Typography>
        <Dropzone/>
        {errors.files ? (
          <Alert severity="error">
            Beim Upload scheint etwas schief gelaufen zu sein. Bitte laden Sie Ihre Dateien erneut hoch.
          </Alert>
        ) : null}
      </Grid>
    </Grid>
  );
}


export default function PollForm({data, storeFormData, hasData, reset, submit, formErrors, isSubmitting}) {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  const form = useForm({
    resolver: yupResolver(POLL_SCHEMA),
    defaultValues: data,
    shouldUnregister: false,
  });
  const {handleSubmit, getValues, reset: resetForm, dirty, setError, errors, clearErrors, watch} = form;
  const hasErrors = errors && Object.keys(errors).length > 0;

  const remember = useMemo(() => () => {
    storeFormData(getValues());
  }, [storeFormData, getValues]);

  const resetAction = useMemo(() => () => {
    if (window.confirm("Sollen die Formulareingaben wirklich gelöscht werden?")) {
      resetForm();
      reset();
      window.location.reload();
    }
  }, [resetForm, reset]);

  const submitAction = useMemo(() => () => {
    remember();
    return handleSubmit(data => {
      submit(data);
    })();
  }, [remember, handleSubmit, submit]);

  useEffect(() => {
    if (!formErrors) {
      clearErrors();
      return;
    }
    for (const [field, error] of Object.entries(formErrors)) {
      setError(field, {type: 'manual', message: error});
    }
  }, [formErrors, clearErrors, setError]);

  if (!hasMounted) {
    return null;
  }

  return (
    <FormProvider {...form}>
      {(hasData || dirty) ? (
        <ResetButton reset={resetAction}/>
      ) : null}

      <Box my={4}>
        <Typography variant="body1" gutterBottom>
          Die Steuerungsgruppe zu XML-Meldungen für Musik an die GEMA befasst sich mit der von den Stationen vertraglich
          vereinbarten Meldung für Musik in der Werbung an die GEMA. Die Passage im Einzelnutzervertrag lautet:
        </Typography>

        <Typography variant="body2" gutterBottom style={{paddingLeft: 20, paddingTop: 20, paddingBottom: 20}}>
          Die Meldung von Werbung im Format GEMAGVL-HF-XML Version 1.1 wird vom Lizenznehmer bei Vermarktung durch einen
          großen Handelsvertreter / eine große Vermarktungsorganisation (50 Mio. EUR Gesamtumsatz exkl. USt oder mehr
          pro Jahr) spätestens bis zum 31.12.2022 umgesetzt.
          Bei Vermarktung durch einen kleineren Handelsvertreter / eine kleinere Vermarktungsorganisation (bis 50 Mio.
          EUR Gesamtumsatz exkl. USt pro Jahr) erfolgt die Umsetzung spätestens bis zum 30.6.2023.
          Bei Vermarktung durch den Lizenznehmer selbst erfolgt die Umsetzung bis zum 31.12.2023.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Für die weitere Umsetzung gemeinsam mit Sendemeldung.de (<Link color="inherit"
                                                                         href="https://www.privatfunk.de/r23/10038.html"
                                                                         target="_blank">www.privatfunk.de/r23/10038.html</Link>)
          sind Informationen aus der Branche notwendig, die hier abgefragt werden.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Ziel
        </Typography>

        <Box my={2}>
          <Typography variant="body1" gutterBottom>
            Jeder einzelne Einsatz eines Werbespots in einem GEMA-meldepflichtigen UKW/DAB-Programm soll gegenüber der
            GEMA gemeldet werden. Wie auch bei der Musik sollen die Ausstrahlungen mit Datum und exaktem Sendezeitraum
            gemeldet werden.
          </Typography>

          <Typography variant="body1" gutterBottom>
            Am Ende soll die Information bei der GEMA im XML-Format ankommen, auch insoweit genauso, wie das
            Sendemeldung.de für die Stationen aus dem bisherigen Format GEMAGVL4 umsetzt. Der Weg über Sendemeldung.de
            ist auch hier optional, jede Station ist frei, sich anderer Wege zu bedienen.
          </Typography>
        </Box>
      </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Voraussetzungen
        </Typography>

        <Box my={2}>
          <VoraussetzungenForm remember={remember}/>
        </Box>
      </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Anlieferungsmöglichkeiten über das Sendesystem
        </Typography>

        <Box my={2}>
          <SendesystemForm remember={remember}/>
        </Box>
      </Box>

      <Collapse in={watch('sendesystem_has_spots') !== 'vorhanden'} component={Box}
                my={watch('sendesystem_has_spots') !== 'vorhanden' ? 4 : -2}>
        <Typography variant="h5" gutterBottom>
          Anlieferungsmöglichkeiten aus dem Dispositionssystem
        </Typography>

        <Box my={2}>
          <DispositionssystemForm remember={remember}/>
        </Box>
      </Collapse>

      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Selbstgebaute Werbespots
        </Typography>

        <Box my={2}>
          <SelbstgebauteWerbespotsForm remember={remember}/>
        </Box>
      </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Möglichkeiten zur Ermittlung der Metadaten eines Spots
        </Typography>

        <Box my={2}>
          <MetadatenForm remember={remember}/>
        </Box>
      </Box>

      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Ihre Kontaktdaten
        </Typography>
        <Box my={2}>
          <StammdatenForm form={form} remember={remember}/>
        </Box>
      </Box>

      <Box my={4}>
        <Typography gutterBottom>
          Es gelten unsere <Link href="/datenschutz/" target="_blank">Datenschutzbestimmungen</Link>.
        </Typography>

        {hasErrors ? (
          <Alert severity="error">
            {errors.global ? errors.global?.message : "Bitte korrigieren Sie Ihre Angaben."}
          </Alert>
        ) : null}

        <Box my={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={submitAction}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size='1rem'/>&nbsp;Formular wird abgesendet...
              </>
            ) : (
              "Formular absenden"
            )}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  )
}
