import React, {useEffect, useState} from "react"
import {PollConfirmation, PollForm} from "./index";


export default function Poll({selectedPoll}) {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    selectedPoll = undefined;
  }

  return (
    selectedPoll ? (
      <PollConfirmation selectedPoll={selectedPoll}/>
    ) : (
      <PollForm/>
    )
  );
}
