import PollConfirmation from "./poll-confirmation-view";
import {connect} from "react-redux";
import {reset} from "../../features/poll";

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    reset: () => dispatch(reset()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PollConfirmation);
