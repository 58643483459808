import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Controller} from "react-hook-form";
import React from "react";

export default function Choices({errors = {}, control, name, variant = 'outlined', fullWidth = true, label, items = {}, margin, onBlur, ...props}) {
  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      margin={margin}
      error={!!errors[name]}
      onBlur={onBlur}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        as={Select}
        control={control}
        defaultValue={''}
        name={name}
        label={label}
        inputProps={{
          id: name,
        }}
        {...props}
      >
        {Object.entries(items).map(([value, label]) => (
          <MenuItem value={value} key={value}>{label}</MenuItem>
        ))}
      </Controller>
      {errors[name] ? <FormHelperText>{errors[name].message}</FormHelperText> : null}
    </FormControl>
  );
}
