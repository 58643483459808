import React, {useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, List, Paper, Typography} from "@material-ui/core";
import {PendingFile, UploadedFile} from "./components";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  list: {
    marginTop: -theme.spacing(4),
  },
  fileInput: {
    display: 'none',
  }
}));

export default function Dropzone({pendingFileInternalIds, uploadedFileInternalIds, uploadFiles}) {
  const classes = useStyles();

  const fileInputRef = useRef();

  const hasFiles = (pendingFileInternalIds.length > 0 || uploadedFileInternalIds.length > 0);

  return (
    <Paper
      variant="outlined"
      onDrop={event => {
        event.stopPropagation();
        event.preventDefault();
        uploadFiles(event.dataTransfer.files);
      }}
      onDragOver={event => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
      }}
    >
      <Typography component="div" variant="body1" className={classes.dropzone}>
        {hasFiles ? (
          <>
            <List className={classes.list}>
              {uploadedFileInternalIds.map(internalId => (
                <UploadedFile key={internalId} internalId={internalId}/>
              ))}
              {pendingFileInternalIds.map(internalId => (
                <PendingFile key={internalId} internalId={internalId}/>
              ))}
            </List>
          </>
        ) : (
          <>
            <p>bitte Sendemeldung(en) hier hineinziehen</p>
            <p>oder</p>
          </>
        )}
        <Button
          variant={hasFiles ? "text" : "contained"}
          color="primary"
          onClick={() => fileInputRef.current.click()}
        >
          {hasFiles ? "weitere Dateien vom Computer hinzufügen" : "Dateien vom Computer auswählen"}
        </Button>
        <input type='file' id='file' multiple ref={fileInputRef} className={classes.fileInput} onChange={event => {
          uploadFiles(event.target.files);
          fileInputRef.current.value = '';
        }}/>
      </Typography>
    </Paper>
  );
}
