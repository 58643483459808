import React from "react";
import {IconButton, Link, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from "@material-ui/icons/Delete";

export default function UploadedFile({name, size, uuid, deleteFile, deletionRequested, authToken}) {
  return (
    <ListItem disabled={deletionRequested}>
      <ListItemIcon>
        <AttachmentIcon/>
      </ListItemIcon>
      <ListItemText
        primary={
          <Link href={`/api/files/${uuid}/blob`} target="_blank">
            {name}
          </Link>
        }
        secondary={size !== undefined ? `${size} bytes` : null}
      />
      {(deleteFile && authToken) ? (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="Löschen" onClick={() => deleteFile(authToken)}>
            <DeleteIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}
