import PollForm from "./poll-form-view";
import {connect} from "react-redux";
import {getFormData, storeFormData, reset, hasData, submit, isSubmitting, getFormErrors} from "../../features/poll";

const mapStateToProps = state => {
  return {
    data: getFormData(state),
    hasData: hasData(state),

    isSubmitting: isSubmitting(state),
    formErrors: getFormErrors(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeFormData: data => dispatch(storeFormData({data})),
    reset: () => dispatch(reset()),
    submit: data => dispatch(submit({data})),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PollForm);
